import { useState, useEffect } from 'react'
import { Auth } from '../../modules/auth/Auth'
import { SCOPES } from '../../../config/constants'
import { appStore } from '../../AppStore'
import { log } from '../log'
import { shouldOmitRedirect } from '../../../config'

/*
  FIXME: extract `reset_password=true` from access token and force password change
  JSON.parse(atob(temp1.split('.')[1]))
*/

/**
 * Authentication & Athorization hook
 *
 * Checks validity of tokens, fetches new access token, redirects to `/auth` page
 * and authorizes user for given scope
 *
 * returns flags as object - `{isAuthenticated, isAuthorized, isLoading }`
 * @param scope - authorization scope of the page
 */
export function useAuth(scope: SCOPES) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    authenticate()
      .then(res => {
        setIsAuthenticated(res)
      })
      .catch(() => {
        setIsAuthenticated(false)
        const redirect = encodeURIComponent(location.pathname + location.search)
        location.href = `/auth?redirect=${redirect}`
      })
      .then(() => {
        return authorize(scope)
          .then(res => setIsAuthorized(res))
          .catch(err => log(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [scope])

  return { isAuthenticated, isAuthorized, isLoading }
}

export function useAuthenticate() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    authenticate()
      .then(res => {
        setIsAuthenticated(res)
      })
      .then(() => appStore.fetchLoggedInUser())
      .then(() => {
        appStore.currentRole = SCOPES.User
      })
      .catch(() => {
        setIsAuthenticated(false)
        const redirect = encodeURIComponent(location.pathname + location.search)
        location.href = `/auth?redirect=${redirect}`
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { isAuthenticated, isLoading }
}

async function authorize(scope: SCOPES) {
  await appStore.fetchLoggedInUser()
  const token = Auth.getAccessToken()

  if (token && Auth.forceChangeEmail(token)) {
    location.pathname = `/auth/change-email`
    return true
  }

  if (appStore.currentUserAccesses.length === 0) location.pathname = '/no-roles'
  return !!appStore.getIdInScope(scope)
}

async function authenticate() {
  let isAuthorized = false

  const accessToken = Auth.getAccessToken()

  if (accessToken && Auth.forcePasswordReset(accessToken)) {
    location.href = `/auth?showChangePassword`
  }

  if (!accessToken || !Auth.isTokenValid(accessToken)) {
    try {
      await Auth.fetchNewAccessToken()
      isAuthorized = true
    } catch (err) {
      // refresh_token missing/invalid
      if (!shouldOmitRedirect()) {
        throw err
      }
    }
  } else {
    isAuthorized = true
  }

  appStore.isLoggedIn = isAuthorized

  return isAuthorized
}
