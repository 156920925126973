import { observable, computed, IKeyValueMap } from 'mobx'
import { orderBy, omitBy } from 'lodash-es'

type TSortDefsMapInitialValue =
  | [string, 'asc' | 'desc'][]
  | IKeyValueMap<'asc' | 'desc'>
  | Map<string, 'asc' | 'desc'>
  | undefined

export class TableSortStore {
  sortDefs = observable.map<string, 'asc' | 'desc'>()

  private defaultSortDefs = observable.map<string, 'asc' | 'desc'>()

  constructor(initiaValue?: TSortDefsMapInitialValue) {
    if (initiaValue) {
      this.defaultSortDefs.replace(initiaValue)
      this.sortDefs.replace(initiaValue)
    }
  }

  resetToDefaults() {
    this.sortDefs.replace(this.defaultSortDefs)
  }

  // TODO: this should be called toggle
  setColumnSortDir = (column: string) => {
    const dir = this.sortDefs.get(column)
    if (!dir) {
      return this.sortDefs.set(column, 'asc')
    }
    if (dir === 'asc') {
      return this.sortDefs.set(column, 'desc')
    }
    if (dir === 'desc') {
      return this.sortDefs.delete(column)
    }
  }

  // @deprecated
  sort(data: any) {
    return orderBy(data, [...this.sortDefs.keys()], [...this.sortDefs.values()])
  }

  @computed get sortDefsAsRecord() {
    const defs: Partial<Record<string, 'asc' | 'desc'>> = {}

    for (const [key, val] of this.sortDefs.entries()) {
      defs[key] = val
    }

    return defs
  }

  /**
   * returns fields in UP API sort format
   *
   * @example
   * sort=name,age&desc=age
   */
  get params() {
    const sort: string[] = []
    const desc: string[] = []

    for (const [key, val] of this.sortDefs.entries()) {
      if (val === 'asc') {
        sort.push(key)
      } else {
        sort.push(key)
        desc.push(key)
      }
    }

    return omitBy<{
      sort: string
      desc: string
    }>(
      {
        sort: sort.join(','),
        desc: desc.join(','),
      },
      i => i === ''
    )
  }
}
