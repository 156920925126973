import { observable, computed, reaction } from 'mobx'
import { Pagination } from '@odiupsk/up-api-client'

/**
 * store used to handle pagination logic
 */
export class TablePaginationStore {
  @observable perPage = 10

  @observable page = 1

  @observable objectCount: number = 0

  constructor(source: Partial<Pick<TablePaginationStore, 'objectCount' | 'page' | 'perPage'>>) {
    Object.assign(this, source)
  }

  @computed get numPages() {
    return Math.ceil(this.objectCount / this.perPage)
  }

  @computed get firstRow() {
    return this.page * this.perPage - this.perPage + 1
  }

  @computed get lastRow() {
    return this.firstRow + this.perPage - 1
  }

  dispose = reaction(
    () => this.perPage,
    () => {
      this.page = 1
    }
  )

  @computed get pagesOptions() {
    return Array.from(Array(this.numPages).keys()).map((_, idx) => ({
      text: String(idx + 1),
      value: String(idx + 1),
    }))
  }

  @computed get params() {
    const { page, perPage: per_page } = this
    return { page, per_page }
  }

  setFromPagination(arg: Pagination) {
    this.perPage = arg.per_page ?? 10
    this.page = arg.page ?? 1
    this.objectCount = arg.object_count ?? 0
  }
}
